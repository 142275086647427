@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.blockquote {
    font-style: italic;
    border-left: 1px;
    border-left-color: black;
    border-left-style: groove;
    padding-left: 1em;
}

.blockquote-footer {
    margin-top: 0.5em;
    font-style: italic;
    font-size: 0.8em;
    color: #666;
}

.fat-row {
    margin-bottom: 4em;
}